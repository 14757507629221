import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InputPassword from 'src/commons/components/Inputs/InputPassword';
import InputText from 'src/commons/components/Inputs/InputText';
import {useAuth} from 'src/commons/contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {CSTE_ROUTES} from 'src/commons/constants';
import {FormLoginContent, useForm} from 'src/commons/models/commons';
import CachedIcon from '@mui/icons-material/Cached';
import {AISCacheService} from 'src/commons/services/cacheService';
import {AISUserService} from 'src/commons/services/dataServices';
import InputTextMask from '../../Inputs/InputTextMask';
import SSOLogin from './SSOLogin';
import InputSelect from '../../Inputs/InputSelect';

type PropsFormLogin = {
  handleLoginSuccess?: Function;
};

function FormLogin({handleLoginSuccess}: PropsFormLogin) {
  const [apiOk] = useState(true);
  const [step, setStep] = useState(1);
  const [modeSuperUser, setModeSuperUser] = useState(false);
  const [value, setValue] = useState('super-admin-off');

  const {logout} = useAuth();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = (event.target as HTMLInputElement).value;

    values.profile.disabled = newValue === 'super-admin-on';
    setValue(newValue);
  };

  const navigate = useNavigate();

  const initialValues: FormLoginContent = {
    login: {
      id: 'create-user-login',
      required: false,
      value: '',
      clearOnInit: true,
      label: 'Login',
      valueChange: (e: string) => onChange('login', e),
      autoFocus: true,
    },
    password: {
      id: 'create-user-password',
      required: false,
      value: '',
      label: 'Password',
      valueChange: (e: string) => onChange('password', e),
    },
    corporateKey: {
      id: 'create-user-corporateKey',
      required: true,
      value: '',
      upperCase: true,
      label: 'Corporate Key e.g. ABC-1234',
      valueChange: (e: string) => onChange('corporateKey', e),
      formatChars: {
        P: '[ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]',
        '0': '[0123456789]',
      },
      mask: 'PPP-0000',
      autoFocus: true,
    },
    profile: {
      id: 'profile-super-user',
      required: false,
      disabled: false,
      value: '',
      options: [],
      label: 'Profile',
      valueChange: (e: string) => onChange('profile', e),
    },
  };

  const [pending, setPending] = useState(false);
  const [showAlert] = useState(false);

  const {login, loginToDispatcher, hasPermission} = useAuth();

  const {onChange, onSubmit, values} = useForm(submitCallBack, initialValues);

  function submitCallBack() {
    setPending(true);
    if (step === 1) {
      loginToDispatcher(values.corporateKey.value, loginDispatcherSuccess, loginDispatcherFailed);
    } else {
      login(values.login.value, values.password.value, loginSuccess, loginFailed);
    }
  }

  const goToStep1 = () => {
    if (modeSuperUser) {
      logout();
      setModeSuperUser(false);
      return;
    }
    setLoginError({hasError: false, message: ''});
    AISCacheService.removeDispatcherResponse();
    values.login.required = false;
    values.password.required = false;
    values.corporateKey.required = true;
    setStep(1);
  };

  const goToStep2 = () => {
    AISCacheService.removeItem('token');
    setLoginError({hasError: false, message: ''});
    values.login.required = true;
    values.password.required = true;
    values.corporateKey.required = false;
    setStep(2);
  };

  const loginDispatcherSuccess = () => {
    setPending(false);
    /**
     * We need to refresh the page to load the theme when you start the application
     */
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  function loginDispatcherFailed(errors: Array<any>) {
    setPending(false);
    setLoginError({hasError: true, message: errors[0].error});
  }

  const loginSuccess = () => {
    setPending(false);
    setLoginError({hasError: false, message: ''});
    // eslint-disable-next-line no-debugger
    if (AISCacheService.isUserAirInt()) {
      values.profile.value = AISCacheService.getUserProfile();
      values.profile.options = AISCacheService.getProfileOptions();
      setModeSuperUser(true);
    } else if (handleLoginSuccess) {
      handleLoginSuccess();
    } else {
      goToSelectorPage();
    }

    // if (handleLoginSuccess) {
    //   handleLoginSuccess();
    // } else if (AISCacheService.isUserAirInt()) {
    //   values.profile.value = AISCacheService.getUserProfile();
    //   values.profile.options = AISCacheService.getProfileOptions();
    //   setModeSuperUser(true);
    // } else {
    //   goToSelectorPage();
    // }
  };

  const goToSelectorPage = () => {
    // Permission management: if the user only has access to one module,
    // they can navigate directly within it.
    // Otherwise, it is directed directly to the module selector.

    if (handleLoginSuccess) {
      handleLoginSuccess();
      return;
    }
    AISCacheService.removeTokenIsExpired();
    const moduleAdmin = hasPermission('administrator');
    const moduleReporting = hasPermission('webreporting');
    const moduleConfig = hasPermission('configurator');

    if (!moduleAdmin && !moduleReporting && !moduleConfig) {
      setLoginError({
        hasError: true,
        message: `Sorry, but you have no right to access the Cabin Portal modules. Please contact your administrator.`,
      });
      return;
    }

    const route = CSTE_ROUTES.COMMONS.APPSELECTOR;
    // let sum = 0;
    // const moduleAdmin = hasPermission("administrator");
    // const moduleReporting = hasPermission("webreporting");
    // const moduleConfig = hasPermission("configurator");

    // if (moduleAdmin) sum += 1;
    // if (moduleReporting) sum += 1;
    // if (moduleConfig) sum += 1;
    // if (sum === 1) {
    //     if (moduleAdmin) route = CSTE_ROUTES.ADMINISTRATOR.OVERVIEW;
    //     if (moduleReporting) route = CSTE_ROUTES.WEBREPORTING.OVERVIEW;
    //     if (moduleConfig) route = CSTE_ROUTES.CONFIGURATOR.OVERVIEW;
    //     AISCacheService.setBaseModuleHref(route);
    // }
    navigate(route);
  };

  function loginFailed(errors: Array<any>) {
    setPending(false);
    setLoginError({hasError: true, message: errors[0].error});
  }

  const [loginError, setLoginError] = useState({
    hasError: false,
    message: 'Login Error',
  });

  useEffect(() => {
    // checkApiConnexion(checkApiConnexionFailed);
    const dispatcherResponse = AISCacheService.getDispatcherResponse();

    if (dispatcherResponse) {
      goToStep2();
    }
  }, []);

  const handleTryAgain = () => navigate(0);

  const handleFormSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  const handleContinue = async () => {
    if (value === 'super-admin-on') {
      const {data: response} = await AISUserService.updateUserSessionProfile(-1);

      if (response.status === 200) {
        const features = response.results;

        AISCacheService.changeUserProfile(AISCacheService.getUserProfile(), features);
        goToSelectorPage();
      }
    } else if (values.profile.value === AISCacheService.getUserProfile()) {
      goToSelectorPage();
    } else {
      const newIdProfile = values.profile.value;
      const {data: response} = await AISUserService.updateUserSessionProfile(newIdProfile);

      if (response.status === 200) {
        const features = response.results;

        AISCacheService.changeUserProfile(newIdProfile, features);
        goToSelectorPage();
      }
    }
  };

  return (
    <Grid justifyContent="center" alignItems="center" container>
      <Grid item xs={12} md={10} mx="auto">
        <form onSubmit={handleFormSubmit}>
          <Box sx={{textAlign: 'center', p: 0}}>
            {showAlert && (
              <Alert severity="warning" sx={{textAlign: 'left', mb: 3}} variant="filled">
                You have been logged out due to long inactivity.{' '}
                <strong>Please, login again.</strong>
              </Alert>
            )}

            {step === 1 && (
              <>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{mb: 4, mt: 4}}
                >
                  Please enter your Corporate Key to get started
                </Typography>
                <FormControl variant="standard" fullWidth>
                  <InputTextMask field={values.corporateKey} />
                </FormControl>
              </>
            )}

            {step === 2 && (
              <>
                <SSOLogin />

                {!modeSuperUser && (
                  <div>
                    <FormControl variant="standard" fullWidth>
                      <InputText field={values.login} />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" sx={{mt: 3}}>
                      <InputPassword field={values.password} />
                    </FormControl>
                  </div>
                )}

                {modeSuperUser && (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        sx={{mb: 1}}
                        value="super-admin-off"
                        control={<Radio />}
                        label="Continue with a specific profile"
                      />
                      <InputSelect field={values.profile} />
                      <FormControlLabel
                        sx={{mt: 1}}
                        value="super-admin-on"
                        control={<Radio />}
                        label="Continue as Super Admin"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </>
            )}
          </Box>

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            mt={2}
          >
            {!apiOk && (
              <>
                <Alert variant="filled" severity="error" sx={{textAlign: 'start', mb: 3}}>
                  <AlertTitle>API Connexion Error</AlertTitle>
                  the api is unreachable, please contact your IT department for more explanation or
                  try again in a few minutes.
                </Alert>
                <LoadingButton
                  onClick={handleTryAgain}
                  loading={pending}
                  variant="outlined"
                  color="primary"
                  startIcon={<CachedIcon />}
                >
                  Try again
                </LoadingButton>
              </>
            )}
            {apiOk && !modeSuperUser && (
              <LoadingButton
                // onClick={handleSubmit}
                fullWidth
                type="submit"
                loading={pending}
                variant="contained"
                color="primary"
                startIcon={<VpnKeyIcon />}
              >
                {step === 1 ? 'START' : 'LOG IN'}
              </LoadingButton>
            )}

            {apiOk && modeSuperUser && (
              <LoadingButton
                onClick={handleContinue}
                fullWidth
                type="button"
                loading={pending}
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
              >
                Continue
              </LoadingButton>
            )}

            <Box>
              {step === 2 && (
                <Button
                  sx={{fontSize: 11}}
                  onClick={goToStep1}
                  size="small"
                  variant="text"
                  startIcon={<KeyboardBackspaceIcon />}
                >
                  {modeSuperUser ? `Back to login` : `Back to Corporate Key`}
                </Button>
              )}
            </Box>
          </Stack>
        </form>

        {loginError.hasError && (
          <Alert variant="outlined" severity="error">
            {loginError.message}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

export default FormLogin;
