/* eslint-disable @typescript-eslint/no-unused-vars */
import {Dialog, DialogTitle, Divider, DialogContent, Alert} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {createContext, useContext, useState, useEffect, useMemo, useRef} from 'react';
import FormLogin from '../components/Login/Form';
import {AISCacheService} from '../services/cacheService';
import {AISUserService} from '../services/dataServices';

// Créer un contexte pour les deux timers
const TimerContext = createContext<{
  startTimerTokenExpired: () => void;
  stopTimerTokenExpired: () => void;
  showLoginDialog: () => void;
  startTimerHeathCheck: () => void;
  stopTimerHeathCheck: () => void;
}>({
  startTimerTokenExpired: () => {},
  showLoginDialog: () => {},
  startTimerHeathCheck: () => {},
  stopTimerTokenExpired: () => {},
  stopTimerHeathCheck: () => {},
});

export function useTimers() {
  return useContext(TimerContext);
}

export function TimerProvider({children}) {
  const [open, setOpen] = useState(false);
  const [openHealthCheck, setOpenHealthCheck] = useState(false);
  const theme = useTheme();
  const idTimerTokenExpired = useRef<NodeJS.Timeout | null>(null);
  const idTimerHealthCheck = useRef<NodeJS.Timeout | null>(null);
  const [title, setTitle] = useState('Your session has expired due to inactivity');

  const showLoginDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    AISCacheService.removeTokenIsExpired();
    window.location.reload();
  };

  /** ***********************************
   *
   * Timer for the check Expiration token
   *
   ************************************* */

  const startTimerTokenExpired = () => {
    if (!idTimerTokenExpired.current) {
      idTimerTokenExpired.current = setInterval(() => {
        if (!openHealthCheck && window.location.pathname !== '/') {
          setOpen(AISCacheService.tokenIsExpired());
        }
      }, 1000);
    }
  };

  const stopTimerTokenExpired = () => {
    if (idTimerTokenExpired.current) {
      clearInterval(idTimerTokenExpired.current);
    }
  };

  /** ********************************
   *
   * Timer for the check connexion
   *
   ********************************* */

  const startTimerHeathCheck = () => {
    if (!idTimerHealthCheck.current) {
      idTimerHealthCheck.current = setInterval(async () => {
        if (
          !open &&
          !AISCacheService.tokenIsExpired() &&
          !openHealthCheck &&
          window.location.pathname !== '/'
        ) {
          const {data: resCheck} = await AISUserService.check();

          if (resCheck.status === 200) {
            if (resCheck.results[0].checkStatus !== 'OK') {
              setTitle('Your session has expired due to inactivity');
              setOpenHealthCheck(true);
              stopTimerHeathCheck();
            }
          } else {
            setTitle('Server connection error');
            setOpenHealthCheck(true);
            stopTimerHeathCheck();
          }
        }
      }, 60000);
    }
  };

  const stopTimerHeathCheck = () => {
    if (idTimerHealthCheck.current) {
      clearInterval(idTimerHealthCheck.current);
    }
  };

  // Mémorisation de l'objet contextuel
  const contextValue = useMemo(
    () => ({
      showLoginDialog,
      startTimerTokenExpired,
      startTimerHeathCheck,
      stopTimerTokenExpired,
      stopTimerHeathCheck,
    }),
    [],
  );

  useEffect(() => {
    startTimerTokenExpired();
    startTimerHeathCheck();
    return () => {
      stopTimerTokenExpired();
      stopTimerHeathCheck();
    };
  }, []);

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
      <Dialog
        open={open || openHealthCheck}
        maxWidth={'lg'}
        onClose={undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{backgroundColor: theme.palette.primary.dark, color: theme.colors.alpha.white[100]}}
        >
          {'Expired session'}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{width: 800}}>
          <Alert severity="error" sx={{mb: 2}}>
            {title}, <strong>please log in again.</strong>
          </Alert>
          <FormLogin handleLoginSuccess={handleClose} />
        </DialogContent>
      </Dialog>
    </TimerContext.Provider>
  );
}
